footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f2e98c;
  width: 600px;
  margin: auto;
  margin-top: -30px;
  padding: 10px 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
footer p {
  color: #558b3d;
  font-family: "Roboto", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
footer a {
  background-color: #95c761;
  color: #fff;
  padding: 6px 30px;
  border-radius: 50px 25px 50px 25px;
  font-size: 16px;
  font-family: "Roboto", serif;
}
@media screen and (max-width: 1200px) {
  footer {
    width: 80%;
    margin: auto;
  margin-top: -30px;
  text-align: center;
  }
}
