.layout_button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.layout_button button {
  background-color: #1877f2;
  color: white;
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.layout_button2 {
  display: flex;
  justify-content: flex-end;
}
.layout_button2 a {
  background-color: #6bb13d;
  color: white;
  padding: 5px 20px;
  border-radius: 50px 25px 50px 25px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 170px;
}
.navbar_ro {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2e98c;
  padding: 10px 25px;
  border-radius: 200px;
  align-self: flex-end;
  width: 600px;
  gap: 0px;
}

.nav_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin: auto;
  z-index: 9999;
  width: 103%;
}
nav {
  width: 100%;
}
.navbar__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  gap: 10px;
  margin: auto;
}
.navbar__link {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.navbar__link2 {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
}
.navbar__link:hover {
  text-decoration: underline 4px solid #bcd48c !important;
}
.navbar__link.active {
  text-decoration: underline 4px solid #bcd48c !important;
}

.navbar__link2 img {
  width: 80%;
}
.dropdown {
  position: absolute;
  background-color: #f2e98c;
  display: block;
  display: flex;
  flex-direction: row;
  list-style: none;
  z-index: 9999;
  margin-top: 0px;
  border-radius: 0px 0px 15px 15px;
  padding: 15px 15px;
}
.dropdown .col {
  display: flex;
  flex-direction: column;
}
.dropdown .border {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.dropdown .navbar__link {
  padding: 0.7rem 0rem;
  background: transparent;
  display: inline-block;
  color: #558b3d;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  text-align: start;
  text-decoration: none;
}
.noborder {
  border: none !important;
}
.dropdown .navbar__link:hover {
  text-decoration: underline 4px solid #bcd48c !important;
}
.navbar__btn {
  width: max-content;
  color: #558b3d;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
}

.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: max-content;
  background-color: transparent;
}
.open {
  background-color: #f2e98c;
}

.nav_column {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1080px) {
  .nav_column {
    flex-direction: column;
  }
  .nav_buttons {
    flex-direction: column;
  }
  .layout_button2 a {
    margin-left: 30px;
  }
  .nav_wrapper {
    background: transparent;
    box-shadow: 0px;
    margin: 0;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .navbar__menu {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 10px 0;
    gap: 0;
  }
  .navbar_ro {
    background-color: transparent;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 35px;
    gap: 0;
    width: 100%;
  }

  .navbar__btn {
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    padding: 0 0;
    font-size: large;
    color: #558b3d;
  }
  .open {
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    padding: 1.5rem 0;
    font-size: xx-large;
    color: #558b3d;
    background-color: transparent;
  }
  .close {
    margin-top: -15px;
  }
  nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2e98c;
    transform: translateY(-100vh);
  }
  .responsive_nav {
    transform: none;
    z-index: 9999;
    height: fit-content;
    flex-direction: column;
    display: flex;
    width: 100%;
  }

  .dropdown {
    position: relative;
    background: #f2e98c;
    padding: 0;
  }
  .dropdown .navbar__link {
    color: #558b3d;
    font-weight: 500;
    padding: 4px 0;
    padding-left: 60px;
  }
  .navbar__link {
    padding: 0.5rem 0.8rem;
  }
  .navbar__link2 {
    padding: 1rem 0.8rem;
  }
  .navbar__link2 img {
    width: 50%;
  }
}
