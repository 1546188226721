
.methode_about_text {
  width: 100%;
  padding: 0;
}
.methode_banner {
  width: 100%;
}
.methode_bio {
  width: 100%;
  margin: 60px 0 0px 0;
  padding: 0 30px;
}
.methode_banner img {
  width: 100%;
}
.methode_about_text h4 {
  color: #6bb13d;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
  line-height: 30px;
}
.methode_bio h3 {
  color: #6bb13d;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
  line-height: 30px;
}

.methode_about_text p {
  color: #5d5d5f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.methode_about_text span {
  color: #5d5d5f;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.methode_wrapper {
  width: 100%;
  text-align: start;
  padding: 0px 10px 50px 20px;

  border: 4px solid #b0d694;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0px;
  height: 100%;
}
.methode_wrapper2 {
  width: 100%;
  text-align: start;
  padding: 0px 10px 50px 20px;
  
  border: 4px solid #b0d694;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0px;
  height: 100%;
}
.wrapper2 {
  padding: 0px 30px 50px 40px;
}
@media (max-width: 1080px) {
  .methode_about_text h4 {
    font-size: 16px;
  }
  .methode_bio h3 {
    font-size: 22px;
  }
  .methode_about_text p {
    font-size: 14px;
  }
  .methode_about_text span {
    font-size: 14px;
  }
 
}
