

.overlay {
    background: rgba(49, 49, 49, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-content {
    background: #fff; 
    border-radius: 8px;
    padding: 20px;
    min-width: 300px;
    max-width: 400px;
    text-align: center;
    position: relative;
    border: 3px solid #e4f1de;
}



p {
    color: #000; 
}

.close-modal {
    font-size: 14px;
    color: #000; 
    background-color: transparent;

}
.open-modal {
    font-size: 14px;
    padding: 12px;
    border-radius: 10px;
    color: #6bb13d; 
    background-color: white;
    border:1px solid #6bb13d ;
    
}

.close-modal:hover {
    
    cursor: pointer;
    text-decoration: underline;

}
.open-modal:hover {
    cursor: pointer;
    background-color: #6bb13d; 
    color: white;

}
.modal-content-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}