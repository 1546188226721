.notifications_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 40px;
  background-color: white;
  

}
.notifications{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 200px;
 

}
.notifications h2{
  color: #75ba46;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", serif;
  line-height: 24px;
  padding: 0px 0px 0px 10px;

}
.notifications table{
  border: 2px solid #75BA46;
    border-radius: 12px;
    border-collapse: 1px solid #B5DA9E;
}
.notifications thead{
  background: #75BA461F;
}
.notifications th{
  padding: 15px;
font-size: 16px;
font-weight: 600;
line-height: 19.36px;
text-align: left;
color: #347011;


}
.notifications td{
  padding: 20px;
font-size: 16px;
font-weight: 400;
line-height: 19.36px;
text-align: left;
color: #828282;

}

.notifications input[type="checkbox"] {
  appearance: none; 
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.notifications input[type="checkbox"]::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 2px solid #347011; /* border color */
  background-color: #fff; /* checkbox background color */
}

.notifications input[type="checkbox"]:checked::after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 12px;
  border: solid #347011; /* checkmark color */
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

@media (max-width: 1280px) {
  .notifications_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 0px;
    background-color: white;
    
  
  }
  .notifications{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 100px;
   
  
  }
}
@media (max-width: 768px) {
  .notifications_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 0px;
    background-color: white;
    
  
  }
  .notifications{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 10px;
  
  }
}
@media (max-width: 600px) {
  .notifications h2{
    font-size: 16px;
  }


  .notifications th{
    padding: 5px;
  font-size: 12px;
 
  
  
  }
  .notifications td{
    padding: 2px;
  font-size: 12px;

  
  }
  
  .notifications input[type="checkbox"] {
    width: 10px;
    height: 10px;
 
  }
  
  .notifications input[type="checkbox"]::before {
  
    width: 10px;
    height: 10px;
  
  }
  
  .notifications input[type="checkbox"]:checked::after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 4px;
    width: 4px;
    height: 4px;
    border: solid #347011; /* checkmark color */
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}