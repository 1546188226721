.actual_dash {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 40px;
  background-color: white;
  
}
.actual_dash_wrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
  
}
.actual_dash_buttons_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  text-align: start;
  padding-right: 0px;
}
.actual_dash_content {
  width: 80%;

}
.actual_dash_content_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.actual_dash_content_button button {
  background-color: #6bb13d;
  color: white;
  padding: 12px 18px;
  font-size: 14px;
  gap: 10px;
  border-radius: 10px;
  text-align: center;
}

.actual_dash_buttons {
  width: 100%;
}
.actual_dash_wrapper h2 {
  color: #75ba46;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", serif;
  line-height: 24px;
  padding: 0px 0px 0px 10px;
}
.actual_dash_buttons button {
  color: #828282;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  margin: 10px 0;
  width: 100%;
  text-align: start;
  padding: 15px 0px 15px 10px;
  border-radius: 12px;
}
.actual_dash_buttonVisible {
  background-color: #fffcdc !important;
  color: #347011 !important;
  font-weight: 500 !important;
}

.actual_dash_div {
  background-color: #e4f1de;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.actual_dash_div_not {
  background-color: #e4f1de;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
}
.actual_dash_div_img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
.actual_dash_div_img_not {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.actual_dash_div_img img {
 
  width: 100%; 
  max-height: 100%; 
  display: block; 
  object-fit: contain;
  border: 2px solid #75BA46;
  border-radius: 12px;
}
.actual_dash_div_date {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
}
.actual_dash_div_date h4{
  color: #828282;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}
.actual_dash_div_content{
display: flex;
  flex-direction: column;
  justify-content: center;
  color: #828282;
}
.actual_dash_div_content *{
padding: 0;
margin: 0;
}
.actual_dash_div_content h2{
  color: #347011;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
}
.actual_dash_div_content p{
  color: #828282;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.actual_dash_div_content_button{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  
}
.actual_dash_div_content_button button{

  color: #347011;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
}
/*create post*/
.create_form_wrapper{
  
  padding: 15px 40px;
  background-color: white;
  
}
.create_form_wrapper .arr{
  background-color: transparent;
  color: #6bb13d;
  font-size: 50px;
}
.create_form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  margin: auto;
  gap: 10px;
  margin-top: 40px;
}
.create_form h2{
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
}
.create_form button{
  background-color: #6bb13d;
  color: white;
  padding: 15px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 10px;
  justify-content: center;

}
.create_form input{
  background-color: #e4f1de;
  width: 100%;
  padding: 15px;
}
.create_form input::placeholder{
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.create_form input:focus {
  outline: none;
}
.create_form .type_radio {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}
.create_form .type_radio label {
 
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.create_form .type_radio div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center !important;  
  width: 100%;
}
.create_form .type_radio div input[type="radio"] {
  appearance: none; 
  width: 12px;
  height: 12px;
  position: relative;
  cursor: pointer;
  background:none;
 top:50%;
}

.create_form .type_radio div input[type="radio"]::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  border: 2px solid #347011; /* border color */
  background-color: #fff; /* radio background color */
}

.create_form .type_radio div input[type="radio"]:checked::after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 5px;
  width: 3px;
  height: 7px;
  border: solid #347011; /* checkmark color */
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
@media (max-width: 1280px) {
  .actual_dash {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 10px;
    background-color: white;
    
  }
}

@media (max-width: 768px) {

  .actual_dash_wrapper {
    display: flex;
    flex-direction: column;
  
  }
  .actual_dash_buttons_wrapper {
    width: 100%;
    text-align: center;
  }
  .actual_dash_content {
    width: 100%;
  
  }
  .actual_dash_content_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .actual_dash_content_button button {
    background-color: #6bb13d;
    color: white;
    padding: 12px 18px;
    font-size: 14px;
    gap: 10px;
    border-radius: 10px;
    text-align: center;
  }
  
  .actual_dash_buttons {
    width: 100%;
  }
  .actual_dash_wrapper h2 {
    color: #75ba46;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    font-family: "Roboto", serif;
    line-height: 24px;
    padding: 0px 0px 0px 10px;
  }
  .actual_dash_buttons button {
    color: #828282;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    margin: 10px 0;
    width: 100%;
    text-align: start;
    padding: 15px 0px 15px 10px;
    border-radius: 12px;
  }
  .actual_dash_buttonVisible {
    background-color: #fffcdc !important;
    color: #347011 !important;
    font-weight: 500 !important;
  }
  
  .actual_dash_div {
    background-color: #e4f1de;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .actual_dash_div_not {
    background-color: #e4f1de;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
  }

  .actual_dash_div_date {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 20px;
  }
  .actual_dash_div_date h4{
    color: #828282;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
  }
  .actual_dash_div_content{
  display: flex;
    flex-direction: column;
    justify-content: center;
    color: #828282;
  }
  .actual_dash_div_content h2{
    color: #347011;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
  }
  .actual_dash_div_content p{
    color: #828282;
    font-family: "Roboto", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
  }
  .actual_dash_div_content_button{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
  }
  .actual_dash_div_content_button button{
  
    color: #347011;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
  }
  .create_form_wrapper{
  
    padding: 15px 0px;
    background-color: white;
    
  }
  .create_form_wrapper .arr{
    background-color: transparent;
    color: #6bb13d;
    font-size: 40px;
  }
  .create_form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    margin: auto;
    gap: 10px;
    margin-top: 40px;
  }
}
