.exr_dash_wrapper{
  display: flex;
  flex-direction: column;
  padding: 15px 40px;
}
.bio_wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.ex_dash_bio {
  text-align: center;
  color: #558b3d;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
  line-height: 35px;
}
.ex_dash_about_slider {
  width: 50%;
  padding: 25px 10px;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 100%;
  background-image: none;
  justify-content: center;
  align-items: center;
  border: 4px solid #b0d694;
  min-height: 1100px;

}
.ex_dash_about_slider img {
  width: 90%;
  margin: auto;
}
.ex_dash_about_text {
  width: 50%;
  padding: 5px 15px;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 1100px;

  border: 4px solid #b0d694;
}
.ex_dash_about_text *{
  margin: 0;
  padding: 0;
}
.ex_dash_about_text p {
  color: #5d5d5f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.ex_dash_wrapper {
  width: 97%;
  margin: auto;
  text-align: start;
  background-image: none;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

@media (max-width: 1080px) {
  .ex_dash_bio {
    width: 100%;
  }
  .ex_dash_about_slider {
    width: 100%;

    height: fit-content;
  }
  .ex_dash_about_text {
    width: 100%;
    height: fit-content;
  }
  .ex_dash_about_text p {
    font-size: 20px;
  }
  .ex_dash_wrapper {
    flex-direction: column;
    justify-content: space-between;
  }
}
