@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Bacasime Antique", serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}
* {
  border: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

html {
  scroll-behavior: smooth;
  background-color: #f0ecec;
  height: 100%;
  margin: 0;
  padding: 0;
}
button:hover, a:hover{
  cursor: pointer !important
  ;
}
.dash_create_form_wrapper{
  padding: 15px 40px;
  background-color: white;
}
.dash_create_form_wrapper .arr{
  background-color: transparent;
  color: #6bb13d;
  font-size: 50px;
}
.dash_create_form_wrapper .arr:hover{
  cursor: pointer;
}
.dash_create_form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  margin: auto;
  gap: 10px;
  margin-top: 40px;
}
.dash_create_form h2{
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
}
.dash_create_form button{
  background-color: #6bb13d;
  color: white;
  padding: 15px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 10px;
  justify-content: center;

}
.dash_create_form input, .dash_create_form select{
  background-color: #e4f1de;
  width: 100%;
  padding: 15px;
}
.dash_create_form input::placeholder{
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dash_create_form input:focus, .dash_create_form select:focus {
  outline: none;
}
.dash_create_form .type_radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}
.dash_create_form .type_radio label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;  
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dash_create_form .type_radio label input[type="radio"] {
  appearance: none; 
  width: 12px;
  height: 12px;
  position: relative;
  cursor: pointer;
  background:none;
}

.dash_create_form .type_radio label input[type="radio"]::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  border: 2px solid #347011; /* border color */
  background-color: #fff; /* radio background color */
}

.dash_create_form .type_radio label input[type="radio"]:checked::after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 5px;
  width: 3px;
  height: 7px;
  border: solid #347011; /* checkmark color */
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.fileurls{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fileurls img{
width: 150px;
height: 120px;
}
@media (max-width: 1080px) {
  .dash_create_form_wrapper .arr{
    
    font-size: 40x;
  }
  .fileurls{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.dash_create_form{
  width: 100%;
}

}
@media (max-width: 600px) {
  header{
    padding: 0;
  }

}



