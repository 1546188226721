
.qui_about_slider {
  width: 50%;
}
.qui_about_slider img {
  width: 100%;
}
.qui_about_text {
  width: 50%;
}

.qui_about_text h4 {
  color: #6bb13d;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.qui_about_text p {
  color: #5d5d5f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.qui_wrapper {
  width: 90%;
  margin: auto;
  text-align: start;
  padding: 40px 40px 25px;
  border: 4px solid #b0d694;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1200px) {
  .qui_wrapper {
    width: 90%;
    padding: 100px 20px;

  }
}
@media (max-width: 1024px) {
  .qui_about_slider {
    width: 100%;
  }
  .qui_about_slider img {
    width: 80%;
    margin: auto;
  }
  .qui_about_text {
    width: 100%;
  }

  .qui_wrapper {
    display: flex;
    flex-direction: column;
  }
}
