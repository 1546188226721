
.dash_carousel_container {
  width: 97%;
  margin: auto;
  background-color: #e4f1de;
  text-align: center;
  padding: 20px 0;
}

.dash_carousel_container a {
  background-color: #6bb13d;
  color: white;
  padding: 10px 25px;
  border-radius: 25px;
  font-size: 14px;
}
.dash_carousel_container .text_container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  margin: auto;
  padding: 40px 0;
}
.dash_carousel_container .text_container p {
  color: #558b3d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.home_dash_bio {
  text-align: center;
  color: #558b3d;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
  width: 95%;
  margin: auto;
  line-height: 35px;
}
.home_dash_about_slider {
  width: 50%;
}
.home_dash_about_text {
  width: 50%;
}
.home_dash_about_text *{
  margin: 0;
  padding: 0;
}
.home_dash_about_slider2 {
  width: 100%;
}
.home_dash_about_text2 {
  width: 100%;
}
.home_dash_about_text h4 {
  color: #6bb13d;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
  line-height: 18px;
}
.home_dash_about_text2 h4 {
  color: #6bb13d;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
}
.home_dash_about_text p {
  color: #5d5d5f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.home_dash_wrapper {
  width: 90%;
  margin: auto;
  text-align: start;
  padding: 5px 20px 25px 20px;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.home_dash_wrapper2 {
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 5px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0px;
  min-height: 580px;

}
.home_dash_wrapper2 .a {
  background-color: #6bb13d;
  color: white;
  padding: 6px 30px;
  border-radius: 50px 25px 50px 25px;
  font-size: 12px;
  font-family: "Roboto", serif;
  margin: 15px 80px;
}
.home_dash_contact {
  width: 95%;
  margin: auto;
  text-align: start;
  padding: 5px 20px;
  align-items: center;
  display: grid;
grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

}
.home_dash_control {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: transparent !important;
  padding: 10px 30px;
  border-radius: 12px;
  color: #75ba46 !important;
  gap: 10px;
}

@media (max-width: 1920px) {
  .home_dash_contact {
    width: 95%;
    margin: auto;
    text-align: start;
    padding: 5px 20px;
    align-items: center;
    display: grid;
  grid-template-columns:  1fr 1fr;
    gap: 20px;
  
  }
}
@media (max-width: 1080px) {
  .home_dash_bio {
    font-size: 16px;

    width: 100%;
  }
  .home_dash_about_slider {
    width: 100%;
  }
  .home_dash_about_slider img {
    width: 80%;
    margin: auto;
  }
  .home_dash_about_slider2 img {
    width: 50%;
  }
  .home_dash_about_text {
    width: 100%;
  }
  .home_dash_about_slider2 {
    width: 100%;
  }
  .home_dash_about_text2 {
    width: 100%;
  }
  .home_dash_about_text h4 {
    font-size: 20px;
  }
  .home_dash_about_text2 h4 {
    font-size: 20px;
    line-height: normal;
  }
  .home_dash_about_text p {
    color: #5d5d5f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .home_dash_wrapper {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background-image: none;
  }
  .home_dash_wrapper2 {
    background-image: none;
    width: 70%;
    min-height: auto;
  }
  .home_dash_wrapper2 .a {
    background-color: #6bb13d;
    color: white;
    padding: 6px;
    border-radius: 50px 25px 50px 25px;
    font-size: 12px;
    font-family: "Roboto", serif;
    margin: 15px 40px;
  }
  .home_dash_contact {
    width: 65%;
    margin: auto;
    text-align: start;
    padding: 5px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
}
@media (max-width: 764px) {
  .home_dash_contact {
    width: 95%;
    margin: auto;
    text-align: start;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
  .home_dash_about_text2 h4 {
    color: #6bb13d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    font-family: "Roboto", serif;
    line-height: normal;
  }
  .home_dash_wrapper {
    width: 97%;
    margin: auto;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}
