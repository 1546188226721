.actual_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}
.actual_buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 30%;
  text-align: start;
}
.actual_content {
  width: 70%;
}
.actual_div1 p {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.actual_div1 span {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.actual_div2 p {
  color: #5d5d5f;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.actual_div2 span {
  color: #5d5d5f;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.actual_div1 .h5 {
  color: #0878b6;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.actual_div2 .h5 {
  color: #0878b6;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.actual_div1 h4 {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.actual_div2 h4 {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.actual_div1 h2 {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
}
.actual_div2 h2 {
  color: #6bb13d;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
}
.actual_div1 h6 {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  margin-top: -10px;
}
.actual_div2 a {
  /*background-color: #95c761;*/
  color: #fff;
  padding: 6px 20px;
  border-radius: 50px 25px 50px 25px;
  font-size: 16px;
  font-family: "Roboto", serif;
}
.actual_div1 a {
  /*background-color: #95c761;*/
  color: #fff;
  padding: 6px 20px;
  border-radius: 50px 25px 50px 25px;
  font-size: 16px;
  font-family: "Roboto", serif;

}
.actual_buttons h2 {
  color: #6bb13d;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
  line-height: 30px;
}
.actual_buttons button {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  margin: 10px 0;
  width: 100%;
  text-align: start;
  padding-left: 10px;
}
.actual_buttonVisible {
  background-color: #e4f1de !important;
  padding: 10px 0;
}
.actual_div1 {
  background-color: #e4f1de;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0px;
}
.actual_div2 {
  margin: auto;
  text-align: start;
  padding: 35px 10px 5px 20px;
  border: 4px solid #b0d694;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0px;
  height: 100%;
}
.actual_div2 * , .actual_div1 * {
  margin: 0;
  padding: 0;
 
}
.actual_div1 img{
 width: 100%;
}
.actual_div2 img{
  width: 100%;
}
@media (max-width: 1080px) {
  .actual_wrapper {
    flex-direction: column;
    gap: 10px;
  }
  .actual_buttons {
    width: 100%;
  }
  .actual_content {
    width: 100%;
  }
  .actual_div1 img {
    width: 100%;
  }
  .actual_div2 img {
    width: 100%;
  }
}
