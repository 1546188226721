
.contact_about_slider {
  width: 50%;
}
.contact_about_slider img {
  width: 100%;
}
.contact_about_text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.contact_about_text a {
  background-color: #6bb13d;
  color: white;
  padding: 10px 20px;
  border-radius: 50px 25px 50px 25px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 100px;
}
.contact_about_text span {
  color: #5d5d5f;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contact_about_text div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  align-items: baseline;
  height: 25px;
}
.contact_about_text p {
  color: #5d5d5f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.contact_wrapper {
  width: 97%;
  margin: auto;
  text-align: start;
  padding: 35px 28px 20% 25px;  
  border: 4px solid #b0d694;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}
@media screen and (max-width: 1080px) {
  .contact_wrapper {
    width: 97%;
    margin: auto;
    text-align: start;
    padding: 35px 28px 20% 25px;
    border: 4px solid #b0d694;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
  }
  .contact_about_text div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    height: 30px;
  }
  .contact_about_text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .contact_about_slider {
    width: 100%;
  }
  .contact_about_text a {
    background-color: #6bb13d;
    color: white;
    padding: 10px 20px;
    border-radius: 50px 25px 50px 25px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 40px;
  }
}