.carousel_container {
  width: 97%;
  margin: auto;
  background-color: #e4f1de;
  text-align: center;
  padding: 20px 0;
}
.carousel_container a {
  background-color: #6bb13d;
  color: white;
  padding: 10px 25px;
  border-radius: 25px;
  font-size: 14px;
}
.text_container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  margin: auto;
  padding: 40px 0;
}
.text_container p {
  color: #558b3d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.home_bio {
  text-align: center;
  color: #558b3d;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
  width: 95%;
  margin: auto;
  line-height: 35px;
}
.home_about_slider {
  width: 50%;
}
.home_about_text {
  width: 50%;
}
.home_about_slider2 {
  width: 100%;
}
.home_about_text2 {
  width: 100%;
}
.home_about_text h4 {
  color: #6bb13d;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
  line-height: 18px;
  height: 10px;
}
.home_about_text2 h4 {
  color: #6bb13d;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
}
.home_about_text p {
  color: #5d5d5f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.home_wrapper {
  width: 97%;
  margin: auto;
  text-align: start;
  padding: 40px 40px 25px;
  border: 4px solid #b0d694;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.home_wrapper2 {
  width: 90%;
  margin: auto;
  text-align: center;
  padding: 5px 0px;
  border: 4px solid #b0d694;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
  min-height: 460px;
}
.home_wrapper2 a {
  background-color: #6bb13d;
  color: white;
  padding: 6px 10px;
  border-radius: 50px 25px 50px 25px;
  font-size: 12px;
  font-family: "Roboto", serif;
  margin: 15px 80px;
}
.home_contact {
  width: 95%;
  margin: auto;
  text-align: start;
  padding: 5px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
}
.slick-slide img{
  width:100% !important
}
@media (max-width: 1200px) {
  .home_wrapper {
    padding: 77px 40px;
    width: 100%;
  }
}
@media (max-width: 1080px) {
  .home_bio {
    font-size: 16px;

    width: 80%;
  }
  .home_about_slider2 img {
    width: 50%;
  }
  .home_about_slider2 {
    width: 100%;
  }
  .home_about_text2 {
    width: 100%;
  }

  .home_about_text2 h4 {
    font-size: 20px;
    line-height: normal;
  }
  .home_wrapper2 a {
    background-color: #6bb13d;
    color: white;
    padding: 6px 30px;
    border-radius: 50px 25px 50px 25px;
    font-size: 12px;
    font-family: "Roboto", serif;
    margin: 15px 40px;
  }
  .home_contact {
    width: 65%;
    margin: auto;
    text-align: start;
    padding: 5px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
}@media (max-width: 1024px) {
  .home_wrapper {
    width: 74%;
    margin: auto;
    text-align: start;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .home_about_slider {
    width: 100%;
  }
  .home_about_slider img {
    width: 80%;
    margin: auto;
  }
  .home_about_text {
    width: 100%;
  }
  .home_about_text h4 {
    font-size: 20px;
  }
  .home_about_text p {
    color: #5d5d5f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
@media (max-width: 764px) {
  .home_contact {
    width: 95%;
    margin: auto;
    text-align: start;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }
  .home_about_text2 h4 {
    color: #6bb13d;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    font-family: "Roboto", serif;
    line-height: normal;
  }
}

@media (max-width: 600px) {
  .home_wrapper {
    width: 100%;
    gap: 20px;
    padding: 0px 16px;
    background-image: none;
    border: 4px solid #b0d694;
  }
  .home_wrapper2 {
    width: 70%;
    background-image: none;
    border: 4px solid #b0d694;
    min-height: 300px;
  }
}
