
.ex_bio {
  text-align: center;
  color: #558b3d;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", serif;
  width: 65%;
  margin: auto;
  line-height: 35px;
}
.ex_about_slider {
  width: 50%;
  padding: 25px 10px;
  border: 4px solid #b0d694;
  min-height: 800px;
  justify-content: center;
  align-items: center;
}
.ex_about_slider img {
  width: 98%;
  margin: auto;
}
.ex_about_text {
  width: 50%;
  padding: 5px 15px;
  

  border: 4px solid #b0d694;
  min-height: 800px;
}
.ex_about_text p {
  color: #5d5d5f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.ex_wrapper {
  width: 97%;
  margin: auto;
  text-align: start;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1080px) {
  .ex_bio {
    width: 100%;
  }
  .ex_about_slider {
    width: 100%;

    height: fit-content;
  }
  .ex_about_text {
    width: 100%;
   
    height: fit-content;
  }
  .ex_about_text p {
    font-size: 20px;
  }
  .ex_wrapper {
    flex-direction: column;
    justify-content: space-between;
  }
}
