.login_form_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: white;
  
}
.login_form_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 200px;
}
.login_form_div2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 100px;
}
.login_bg {
  height: 100%;
  width: 100%;
}
.verify_form_wrapper {
  align-items: center;
  text-align: center;
}
.logo_login {
  width: 32%;
}

.login_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0;
}
.login_button button {
  background-color: #6bb13d;
  color: white;
  padding: 20px 40px;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 10px;
}
.login_button button:hover {
  cursor: pointer;
}
.login_form_wrapper form {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 30px;
  align-items: center;
}

.login_text_field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  justify-content: center;
}

.login_text_field input {
  background-color: #e4f1de;
  width: 400px;
  height: 72px;
  border-radius: 12px;
  padding: 0 25px;
}
.login_text_field h6 {
  color: #347011;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.36px;
  margin: 0;
}
.login_text_field input::placeholder {
  color: #c8c8c8;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.94;
}
.login_text_field input:focus {
  outline: none;
}
.error {
  padding: 10px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 5px;
  text-align: center;
}
@media (max-width: 1920px) {
  .login_form_wrapper {
    min-height: 1080px;
  }
}

@media (max-width: 1280px) {
  .login_form_wrapper {
    grid-template-columns: 2fr 1.5fr;
    min-height: 800px;
  }
  .login_form_div {
    gap: 40px;
  }

  .login_bg {
    height: 100%;
    width: 100%;
  }

  .logo_login {
    width: 45%;
  }
  .login_button button {
    padding: 15px 40px;
    font-size: 16px;

    gap: 10px;
  }

  .login_form_wrapper form {
    gap: 20px;
  }

  .login_text_field {
    gap: 10px;
  }

  .login_text_field input {
    width: 300px;
    height: 52px;
  }
  .login_text_field h6 {
    font-size: 16px;
  }
  .login_text_field input::placeholder {
    font-size: 12px;
  }

  .error {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
.none{display: none;}
.login_form_wrapper {
  grid-template-columns: 1fr;
}
.logo_login {
  width: 200px;
}
}
@media (max-width: 600px) {
}
