
.contact_form_wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;

  width: 95%;
}
.contact_button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.contact_button button {
  background-color: #6bb13d;
  color: white;
  padding: 15px 20px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.math_question {
  display: flex;
  flex-direction: column;
  background-image: url(../../assets/bg_border0.svg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  padding: 10px 20px;
}
.math_question span {
  color: red;
}
.math_question h6 {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 35%;
}
.math_question h5 {
  font-size: 12px;
}
.math_question p,
.legal,
.math_question h3 {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.math_question input {
  border: 1px solid lightgray;
  height: 30px;
  width: 15%;
}
.contact_form_wrapper form {
  width: 65%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact_text_field {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
}
.math_question_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 15px;
}
.contact_text_field input,
.contact_text_field textarea {
  background-color: #e4f1de;
  width: 100%;
  padding: 15px;
}
.contact_text_field input::placeholder,
.contact_text_field textarea::placeholder {
  color: #558b3d;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact_text_field input:focus,
.contact_text_field textarea:focus, .math_question input:focus {
  outline: none;
}
@media (max-width: 764px) {
  .contact_form_wrapper form {
    width: 100%;
    margin: auto;
  }
  .math_question input {
    width: 50px;
  }
  .math_question h6 {
    width: 100%;
  }
  .math_question {
    background-image: none;
    border: 4px solid #b0d694;
  }
}
